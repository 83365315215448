// import React, { useState, useEffect } from 'react';
// import { Link, useLocation } from 'react-router-dom';
// import './Header.css';
// import pizzalogo from "../../assets/Images/Home/PizzaLogo.png";
// import { FaBars, FaTimes } from 'react-icons/fa';

// const Header = () => {
//   const [scroll, setScroll] = useState(false);
//   const [isMobile, setIsMobile] = useState(false);
//   const [isDrawerOpen, setIsDrawerOpen] = useState(false);
//   const location = useLocation(); // Get the current route
//   const [selectedMenu, setSelectedMenu] = useState('Home');

//   const MENU_ITEMS = ['Home', 'About', 'Menu', 'Service', 'Location'];

//   // Define routes for menu items
//   const menuRoutes = {
//     Home: '/',
//     About: '/about',
//     Menu: '/menu',
//     Service: '/service',
//     Location: '/location',
//   };

//   useEffect(() => {
//     const path = location.pathname.substring(1) || 'home'; // Get the path without the leading '/'
//     setSelectedMenu(path.charAt(0).toUpperCase() + path.slice(1)); // Capitalize first letter to match menu names
//   }, [location]); // This runs whenever the route changes

//   useEffect(() => {
//     const handleScroll = () => {
//       setScroll(window.scrollY > 50);
//     };

//     window.addEventListener('scroll', handleScroll);
//     return () => {
//       window.removeEventListener('scroll', handleScroll);
//     };
//   }, []);

//   const handleMenuClick = () => {
//     if (isMobile) {
//       setIsDrawerOpen(false); // Close drawer after menu item click
//     }
//   };

//   const toggleDrawer = () => {
//     setIsDrawerOpen((prev) => !prev);
//   };

//   const handleResize = () => {
//     setIsMobile(window.innerWidth <= 768);
//   };

//   useEffect(() => {
//     handleResize();
//     window.addEventListener('resize', handleResize);
//     return () => {
//       window.removeEventListener('resize', handleResize);
//     };
//   }, []);
  
//   return (
//     <div>
//       {!isMobile ? (
//         <header className={`header ${scroll ? 'header--scrolled' : ''}`}>
//           <div className="header__logo">
//             <img src={pizzalogo} alt="Pizza Logo" className="logo" />
//             <span className="brand-name">Pizza Da Valter</span>
//           </div>
//           <nav className="header__menu">
//             <ul>
//               {MENU_ITEMS.map((menu) => (
//                 <li
//                   key={menu}
//                   className={selectedMenu === menu ? 'selected' : ''}
//                   onClick={handleMenuClick}
//                 >
//                   <Link to={menuRoutes[menu]}>{menu}</Link>
//                 </li>
//               ))}
//             </ul>
//           </nav>
//           <div className="header__order">
//             <Link to="/download">
//               <button className="order-btn">Order Now</button>
//             </Link>
//           </div>
//         </header>
//       ) : (
//         <div className="mobile-header">
//           <div className="mobile-header-left">
//             <img src={pizzalogo} alt="Logo" className="mobile-header-logo" />
//             <span className="mobile-header-brand-name">Pizza Da Valter</span>
//           </div>
//           <div className={`mobile-header-right ${isDrawerOpen ? 'mobile-header-button-move-right' : ''}`}>
//             <Link to="/download">
//               <button className="mobile-header-extra-button" onClick={() => setIsDrawerOpen(false)}>
//                 Order Now
//               </button>
//             </Link>
//             <button
//               className="mobile-header-drawer-toggle"
//               onClick={toggleDrawer}
//               aria-expanded={isDrawerOpen}
//               aria-label={isDrawerOpen ? "Close menu" : "Open menu"}
//             >
//               {isDrawerOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
//             </button>
//           </div>
//           {isDrawerOpen && (
//             <div className="mobile-header-drawer mobile-header-drawer-open">
//               <ul>
//                 {MENU_ITEMS.map((menu) => (
//                   <li
//                     key={menu}
//                     className={selectedMenu === menu ? 'selected' : ''}
//                     onClick={handleMenuClick}
//                   >
//                     <Link to={menuRoutes[menu]}>{menu}</Link>
//                   </li>
//                 ))}
//               </ul>
//             </div>
//           )}
//         </div>
//       )}
//     </div>
//   );
// };

// export default Header;



import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PDFModal from './PdfViewerModal'; // Import your PDF Modal component
import './Header.css';
import pizzalogo from "../../assets/Images/Home/PizzaLogo.png";
import { FaBars, FaTimes } from 'react-icons/fa';
import Pizza_de_valter from "../../assets/PDF/Pizza_de_Valter_Menu.pdf";
import Dessert from "../../assets/PDF/Dessert Menu.pdf";
import Drinks from "../../assets/PDF/Drinks Menu.pdf";

const Header = () => {
  const [scroll, setScroll] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPDF, setSelectedPDF] = useState(null);
  const location = useLocation();
  const [selectedMenu, setSelectedMenu] = useState('Home');
  const [selectedSubMenu, setSelectedSubMenu] = useState(null); // New state for selected sub-menu

  const MENU_ITEMS = ['Home', 'About', 'Menu', 'Service', 'Location'];
  const menuRoutes = {
    Home: '/',
    About: '/about',
    Menu: '/',
    Service: '/service',
    Location: '/location',
  };

  const pdfFiles = {
    Pizza: Pizza_de_valter,
    Dessert: Dessert,
    Drinks: Drinks,
  };

  useEffect(() => {
    const path = location.pathname.substring(1) || 'home';
    setSelectedMenu(path.charAt(0).toUpperCase() + path.slice(1));
  }, [location]);

  useEffect(() => {
    const handleScroll = () => {
      setScroll(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleMenuClick = (menu) => {
    if (isMobile) {
      setIsDrawerOpen(false);
    }
    
    // Close the PDF modal when navigating to another menu item
    if (menu !== 'Menu') {
      closePDFModal();
    }
    
    setSelectedMenu(menu);
  };

  const toggleDrawer = () => {
    setIsDrawerOpen((prev) => !prev);
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  const openPDFModal = (pdf, subMenu) => {
    setSelectedPDF(pdf);
    setIsModalOpen(true);
    setIsDrawerOpen(false); // Close drawer when opening PDF
    setIsDropdownOpen(false); // Close dropdown when opening PDF
    setSelectedSubMenu(subMenu); // Set selected sub-menu
  };

  const closePDFModal = () => {
    setIsModalOpen(false);
    setSelectedPDF(null);
    setSelectedSubMenu(null); // Reset selected sub-menu
    setSelectedMenu(null);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      {!isMobile ? (
        <header className={`header ${scroll ? 'header--scrolled' : ''}`}>
          <div className="header__logo">
            <img src={pizzalogo} alt="Pizza Logo" className="logo" />
            <span className="brand-name">Pizza Da Valter</span>
          </div>
          <nav className="header__menu">
            <ul>
              {MENU_ITEMS.map((menu) => (
                <li
                  key={menu}
                  className={selectedMenu === menu ? 'selected' : ''}
                  onClick={() => handleMenuClick(menu)} // Updated here
                  onMouseEnter={() => menu === 'Menu' && setIsDropdownOpen(true)}
                  onMouseLeave={() => menu === 'Menu' && setIsDropdownOpen(false)}
                >
                  <Link to={menuRoutes[menu]}>{menu}</Link>
                  {menu === 'Menu' && (
                        <>
                        <button onClick={() => setIsDropdownOpen((prev) => !prev)} className="web-dropdown-toggle">
                          {isDropdownOpen ? '▲' : '▼'}
                        </button>
                    {isDropdownOpen && (
                    
                    <ul className="manu-dropdown-web">
                                       <li 
                        className={selectedSubMenu === 'Dessert' ? 'active-submenu' : ''} 
                        onClick={() => openPDFModal(pdfFiles.Dessert, 'Dessert')}
                      >
                        Dessert
                      </li>
                      <li 
                        className={selectedSubMenu === 'Pizza' ? 'active-submenu' : ''} 
                        onClick={() => openPDFModal(pdfFiles.Pizza, 'Pizza')}
                      >
                        Pizza Da Valter
                      </li>
     
                      <li 
                        className={selectedSubMenu === 'Drinks' ? 'active-submenu' : ''} 
                        onClick={() => openPDFModal(pdfFiles.Drinks, 'Drinks')}
                      >
                        Drinks
                      </li>
                    </ul>
                       )}
                      </>
                  )}
                </li>
              ))}
            </ul>
          </nav>
          <div className="header__order">
            <Link to="/download">
              <button className="order-btn">Order Now</button>
            </Link>
          </div>
        </header>
      ) : (
        <div className="mobile-header">
          <div className="mobile-header-left">
            <img src={pizzalogo} alt="Logo" className="mobile-header-logo" />
            <span className="mobile-header-brand-name">Pizza Da Valter</span>
          </div>
          <div className="mobile-header-right">
          <Link to="/download">
             <button className="mobile-header-extra-button" onClick={() => setIsDrawerOpen(false)}>
                Order Now
              </button>
          </Link>
            <button
              className="mobile-header-drawer-toggle"
              onClick={toggleDrawer}
              aria-expanded={isDrawerOpen}
              aria-label={isDrawerOpen ? "Close menu" : "Open menu"}
            >
              {isDrawerOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
            </button>
          </div>
          {isDrawerOpen && (
            <div className="mobile-header-drawer mobile-header-drawer-open">
              <ul>
                {MENU_ITEMS.map((menu) => (
                  <li 
                  key={menu} 
                  className={selectedMenu === menu ? 'selected' : ''}
                  style={{ display: 'flex' }}>
                    <Link to={menuRoutes[menu]} onClick={() => handleMenuClick(menu)}>
                      {menu}
                    </Link>
                    {menu === 'Menu' && (
                      <>
                        <button onClick={() => setIsDropdownOpen((prev) => !prev)} className="dropdown-toggle">
                          {isDropdownOpen ? '▲' : '▼'}
                        </button>
                        {isDropdownOpen && (
                          <ul className="mobile-dropdown">
                                            <li 
                              className={selectedSubMenu === 'Dessert' ? 'active-submenu' : ''} 
                              // onClick={() => openPDFModal(pdfFiles.Dessert, 'Dessert')}
                            > <a href='https://drive.google.com/file/d/1VQRY9qSB14BYN4Ccield1qO3MWA9zEKq/view?usp=drive_link'>
                              Dessert
                              </a></li> 
                           <li 
                              className={selectedSubMenu === 'Pizza' ? 'active-submenu' : ''} 
                              // onClick={() => openPDFModal(pdfFiles.Pizza, 'Pizza')}
                            ><a href='https://drive.google.com/file/d/1G36iKA40j3JbtoLFtTgYBA7Kpr42Ytly/view?usp=drive_link'> 
                              Pizza Da Valter
                              </a> </li>
          
                         <li 
                              className={selectedSubMenu === 'Drinks' ? 'active-submenu' : ''} 
                              // onClick={() => openPDFModal(pdfFiles.Drinks, 'Drinks')}
                            > <a href='https://drive.google.com/file/d/1tU5mwlrJqLq5n1Su-3ge24jdjC_MhAUj/view?usp=drive_link'> 
                              Drinks
                              </a> </li>
                          </ul>
                        )}
                      </>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}

      {/* PDF Modal Component */}
      <PDFModal isOpen={isModalOpen} onClose={closePDFModal} pdfUrl={selectedPDF} />
    </div>
  );
};

export default Header;

