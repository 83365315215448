// src/components/PDFModal.js

import React from 'react';
import './PdfViewerModal.css';
import { FaTimes } from 'react-icons/fa';


const PDFModal = ({ isOpen, onClose, pdfUrl }) => {
  if (!isOpen) return null;

  return (
    <div className="pdf-modal-overlay">
      <div className="pdf-modal-content">
        <button className="pdf-modal-close" onClick={onClose}><FaTimes /></button>
        <iframe
          src={pdfUrl}
          title="PDF Viewer"
          width="100%"
          height="100%"
        />
      </div>
    </div>
  );
};

export default PDFModal;
